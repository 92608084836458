import LinkedInIcon from "../images/icons/linkedin-32x32.png";

const website_data = {
    'angel_anguita': {
        'type': "single_content",
        'ES': {
        image:"/images/team/angelanguita.jpg",
        title:"Angel Anguita",
        subtitle:"Socio / Chief Technology Officer",
        content:`
Ángel es Ingeniero Civil Telemático de la Universidad Técnica Federico Santa María. En su carrera se ha dedicado a todos los aspectos del diseño, creación e implementación de soluciones de software. Su carrera se ha desarrollado en distintas áreas, primero como consultor, donde ha asesorado a grandes empresas en el diseño e implementación de soluciones tecnologías. Segundo en Bank of América, donde formó parte de equipos de tecnología liderando proyectos de desarrollo e implementación para el negocio, además de llevar adelante otras funciones dentro del área como líder de soporte, y administración de proveedores tecnológicos. Finalmente, en Amazon como desarrollador, radicándose en Seattle, USA durante ese periodo.

**Educación**

- MSc Ingenieria Civil Telemática, Universidad Técnica Federico Santa María (2012)
- Ingeniero Civil Telemático, Universidad Técnica Federico Santa María (2010)

**Experiencia Laboral**

- Amazon (2019 – 2022) Software Development Engineer II
- Bank of America (2014 – 2019) Programmer Professional Markets, Assistant Vice President

**Idiomas**

- Español
- Inglés

[![LinkedIn](${LinkedInIcon})](https://www.linkedin.com/in/angel-anguita-osorio/)

`
    },
    'EN':{
        image:"/images/team/angelanguita.jpg",
        title:"Angel Anguita",
        subtitle: "Partner / Chief Technology Officer",
        content:`
 
Ángel is a Telecommunications Civil Engineer from the Federico Santa María Technical University. In his career, he has dedicated himself to all aspects of the design, creation, and implementation of software solutions. His career has developed in various areas, first as a consultant, where he has advised large companies in the design and implementation of technological solutions. Secondly, at Bank of America, where he was part of technology teams leading development and implementation projects for the business, in addition to other roles within the area such as support leader, and management of technological suppliers. Finally, at Amazon as a developer, residing in Seattle, USA during that period.

**Education**

- MSc Telecommunications Civil Engineering, Federico Santa María Technical University (2012)
- Telecommunications Civil Engineer, Federico Santa María Technical University (2010)

**Work Experience**

- Amazon (2019 – 2022) Software Development Engineer II
- Bank of America (2014 – 2019) Programmer Professional Markets, Assistant Vice President

**Languages**

- Spanish
- English

[![LinkedIn](${LinkedInIcon})](https://www.linkedin.com/in/angel-anguita-osorio/)
`
    }   

},
    'eduardo_anguita': {
        'type': "single_content",
        'ES':{
        image:"/images/team/eduardoanguita.jpg",
        title:"Eduardo Anguita",
        subtitle:"Socio / Legal Managing Partner",
        content:`
Eduardo enfoca su práctica en materias corporativas y de compliance. En este contexto, asesora a clientes, en transacciones de fusiones y adquisiciones, negociaciones comerciales complejas, así como en implementaciones de programas de cumplimiento investigaciones internas y estructuración de gobiernos corporativos

**Áreas de Practica**

Banca y finanzas, corporativo y negocios, mercado de valores, empresas familiares, grupos empresariales, fusiones y adquisiciones, tecnológica, e-commerce, venture capital, private equity, compliance, empresas y derechos humanos.
Cuenta con una amplia experiencia asesorando a clientes nacionales e internacionales en materias de gobierno corporativos, desinversiones, reestructuraciones, capitalizaciones, pactos de accionistas, y en otras materias comerciales y estratégicas. Así en el área societaria y comercial, a asesorado sus clientes a través de todo el ciclo de vida de inversión, desde capitalizaciones iniciales en una sociedad u otro vehículo hasta su venta. Habiendo liderado procesos de M&A de empresas de diversos rubros.

En materias de cumplimiento, asesora a directorios, gerencias generales y compliance officers en procesos de implementación de planes de cumplimiento, revisión de protocolos y reglamentos, due diligence, investigaciones internas, procesos de capitalización y otros.

**Educación**

- Abogado, Universidad Adolfo Ibáñez (2013)

**Experiencia Laboral**

- Albagli Zaliasnik (2019 – 2022)
- Barros Errázuriz (2013 – 2019)

**Idiomas**

- Español
- Inglés

[![LinkedIn](${LinkedInIcon})](https://www.linkedin.com/in/eduardo-anguita-osorio-7bb682108/)
`
        },
        'EN':{
        image:"/images/team/eduardoanguita.jpg",
        title:"Eduardo Anguita",
        subtitle:"Partner / Legal Managing Partner",
        content:`
Eduardo focuses his practice on corporate matters and compliance. In this context, he advises clients on merger and acquisition transactions, complex business negotiations, as well as on the implementation of compliance programs, internal investigations, and the structuring of corporate governance.

**Practice Areas**
        
Banking and finance, corporate and business, securities market, family businesses, business groups, mergers and acquisitions, technology, e-commerce, venture capital, private equity, compliance, business and human rights. He has extensive experience advising national and international clients on corporate governance matters, divestments, restructurings, capitalizations, shareholder agreements, and other commercial and strategic matters. In the corporate and commercial area, he has advised his clients throughout the entire investment life cycle, from initial capitalizations in a company or other vehicle to their sale. He has led M&A processes for companies in various sectors.
        
In compliance matters, he advises boards of directors, general managements, and compliance officers in the implementation of compliance plans, review of protocols and regulations, due diligence, internal investigations, capitalization processes, and others.
        
**Education**
        
- Lawyer, Adolfo Ibáñez University (2013)
        
**Work Experience**
        
- Albagli Zaliasnik (2019 – 2022)
- Barros Errázuriz (2013 – 2019)
        
**Languages**
        
- Spanish
- English

[![LinkedIn](${LinkedInIcon})](https://www.linkedin.com/in/eduardo-anguita-osorio-7bb682108/)
        `
        }
    },
    'nathalie_koenig':{
        'ES':{
            image:"/images/team/nathaliekoenig.jpg",
            title:"Nathalie Koenig",
            subtitle:"Abogado",
            content:`
Nathalie Koenig (Universidad Adolfo Ibáñez - 2020) Asociada corporativa, enfoca su práctica en materias corporativas y compliance. 

Licenciada en Ciencias Jurídicas y Sociales de la Universidad Adolfo Ibáñez con mención en Derecho Privado y Minor en Historia. 

Participó y lideró equipo de la Universidad Adolfo Ibáñez en la XII y XIII Competencia Internacional de Arbitraje Comercial, Asunción, Paraguay, septiembre 2019 y Quito, Ecuador Septiembre de 2020. Además, participó en la VII Competencia Arbitraje Internacional de Inversión, Washington D.C. EE.UU. en marzo de 2020. 

Nathalie también fue ayudante de las cátedras de Contratos, Responsabilidad civil y Recursos y procedimientos de ejecución.  

Previo a su trabajo en AnguitaOsorio Nathalie fue procuradora del equipo de compliance (2020) y asociada del equipo corporativo (2022) de Albagli Zaliasnik. 

**Educación** 

- Abogado, Universidad Adolfo Ibáñez (2020) 

**Experiencia Laboral**

- Procuradora área de Compliance y Corporativo - Albagli Zaliasnik (2020 – 2022) 

- Asociada Grupo Corporativo – Albagli Zaliasnik (2022)  


[![LinkedIn](${LinkedInIcon})](https://www.linkedin.com/in/nathalie-koenig-piffaut-7460421a5/)
`

        },
        'EN':{
            image:"/images/team/nathaliekoenig.jpg",
            title:"Nathalie Koenig",
            subtitle:"Lawyer",
            content:`
Nathalie Koenig (Adolfo Ibáñez University - 2020) Corporate Associate, focusing her practice on corporate matters and compliance.

Bachelor of Legal and Social Sciences from Adolfo Ibáñez University with a specialization in Private Law and a Minor in History.

She participated and led the Adolfo Ibáñez University team in the XII and XIII International Commercial Arbitration Competitions, Asunción, Paraguay, September 2019, and Quito, Ecuador, September 2020. Additionally, she took part in the VII International Investment Arbitration Competition, Washington D.C., USA, in March 2020.

Nathalie also served as a teaching assistant for the courses on Contracts, Civil Liability, and Enforcement Procedures.

Prior to her work at AnguitaOsorio, Nathalie was a clerk in the compliance team (2020) and an associate in the corporate team (2022) at Albagli Zaliasnik.

**Education**

- Lawyer, Adolfo Ibáñez University (2020)

**Professional Experience**

- Compliance and Corporate Clerk - Albagli Zaliasnik (2020 – 2022)

- Corporate Group Associate – Albagli Zaliasnik (2022)


[![LinkedIn](${LinkedInIcon})](https://www.linkedin.com/in/nathalie-koenig-piffaut-7460421a5/)

        `
        }
    },
    'andres_matheus':{
        'ES':{
            image:"/images/team/andresmatheus.jpg",
            title:"Andres Matheus Casanova",
            subtitle:"Abogado",
            content:`
Andrés Matheus Casanova (Universidad Adolfo Ibáñez - 2020) 

Asociado Corporativo, enfoca su práctica en materias corporativas, venture capital y compliance. 

Licenciado en Ciencias Jurídicas y Sociales de la Universidad Adolfo Ibáñez con mención en Derecho Civil.  

Participó y lideró el equipo de la Universidad Adolfo Ibáñez en la XII y XIII Competencia Internacional de Arbitraje Comercial, Asunción, Paraguay, en septiembre de 2019 y Quito, Ecuador, en septiembre de 2020. Además, participó en la VII Competencia de Arbitraje Internacional de Inversión, Washington D.C., EE.UU., en marzo de 2020.  

Actualmente lidera el equipo que representará a la Universidad Adolfo Ibáñez en la XVII Competencia Internacional de Arbitraje Comercial, Quito, Ecuador, en septiembre de 2024. 

Además, Andrés se desempeñó como ayudante durante el período 2019-2020 en las cátedras de Derecho Internacional Público, Contratos y Responsabilidad Civil. 

**Educación**

- Abogado, Universidad Adolfo Ibáñez (2020) 

**Experiencia Laboral**

- Abogado, Albagli Zaliasnik (2022) 

- Procurador, Albagli Zaliasnik (2022) 

- Procurador, Cortés & Zamora Abogados (2020-2021) 

- Pasante, Bofill Mir & Álvarez Jana Abogados (2020) 

[![LinkedIn](${LinkedInIcon})](https://www.linkedin.com/in/andr%C3%A9s-matheus-casanova-4512801a0/)
            `
        },
        'EN':{
            image:"/images/team/andresmatheus.jpg",
            title:"Andres Matheus Casanova",
            subtitle:"Lawyer",
            content:`
Andrés Matheus Casanova (Universidad Adolfo Ibáñez - 2020) 

Corporate Associate, focuses his practice on corporate matters, venture capital, and compliance.

Graduated in Legal and Social Sciences from Universidad Adolfo Ibáñez with a mention in Civil Law.

He participated in and led the Universidad Adolfo Ibáñez team in the XII and XIII International Commercial Arbitration Competition, Asunción, Paraguay, in September 2019 and Quito, Ecuador, in September 2020. Additionally, he participated in the VII International Investment Arbitration Competition, Washington D.C., USA, in March 2020.

He currently leads the team that will represent Universidad Adolfo Ibáñez in the XVII International Commercial Arbitration Competition, Quito, Ecuador, in September 2024.

Furthermore, Andrés served as a teaching assistant during the period 2019-2020 in the courses of Public International Law, Contracts, and Civil Liability.

**Education**

- Lawyer, Universidad Adolfo Ibáñez (2020)

**Work Experience**

- Lawyer, Albagli Zaliasnik (2022)

- Legal Assistant, Albagli Zaliasnik (2022)

- Legal Assistant, Cortés & Zamora Abogados (2020-2021)

- Intern, Bofill Mir & Álvarez Jana Abogados (2020)

[![LinkedIn](${LinkedInIcon})](https://www.linkedin.com/in/andr%C3%A9s-matheus-casanova-4512801a0/)
            `
        }
    },
    'karem_alexander':{
        'ES':{
            image:"/images/team/karemalexander.jpg",
            title:"Karem Alexander",
            subtitle:"Finanzas",
            content:`
Karem Alexander (Escuela Nacional de Administración y Hacienda Pública - 2014) Licenciada en Ciencias Fiscales Mención Aduanas y Comercio Exterior. 

A lo largo de su carrera se ha dedicado a generar experiencia en el área de administración y finanzas de varias empresas.  

Previo a su trabajo en AnguitaOsorio Karem fue Analista de Administración y Finanzas dentro de una Filial del Banco del Estado (2023) 

**Educación**

- Lic. Ciencias Fiscales, ENAHP (2014) 

**Experiencia Laboral**

- Analista de Administración y Finanzas - Sociedad de Servicios Transacciones CajaVecina S.A. (2017 - 2023) 


[![LinkedIn](${LinkedInIcon})](https://www.linkedin.com/in/karem-alexander-5ab57811a/)
`

        },
        'EN':{
            image:"/images/team/karemalexander.jpg",
            title:"Karem Alexander",
            subtitle:"Finance",
            content:`
Karem Alexander (National School of Administration and Public Finance - 2014) Bachelor in Fiscal Sciences, specializing in Customs and Foreign Trade.

Throughout her career, she has dedicated herself to gaining experience in the field of administration and finance in various companies.

Prior to her work at AnguitaOsorio, Karem was an Administration and Finance Analyst within a subsidiary of the State Bank (2023).
            
**Education**
            
- B.Sc. in Fiscal Sciences, ENAHP (2014)

**Work Experience**
            
- Administration and Finance Analyst - Sociedad de Servicios Transacciones CajaVecina S.A. (2017 - 2023)


[![LinkedIn](${LinkedInIcon})](https://www.linkedin.com/)

        `
        }
    },
    'jt_cuevas':{
        'ES':{
            image:"/images/team/josetomas.jpg",
            title:"José Tomás Cuevas",
            subtitle:"Director del Area Corporativa",
            content:`
José Tomás Cuevas es abogado con una sólida formación académica y una amplia experiencia en el ámbito corporativo y académico. Obtuvo su licenciatura en Derecho de la Universidad de los Andes y un Magíster en Derecho Comercial y Corporativo (LLM) por la University of Southampton, Reino Unido. A lo largo de su carrera, ha trabajado en diversas oficinas de gran prestigio en el país, destacándose en roles de liderazgo y asesoría en importantes firmas y organizaciones. 

Su carrera se ha centrado en los diversos aspectos del derecho corporativo y comercial, en las cuales ha liderando proyectos clave y ha dado asesoría estratégica en una gran variedad de sectores de la economía. 

Actualmente, es Director del Área Corporativa en AnguitaOsorio, donde lidera un equipo de profesionales dedicados a brindar asesoría legal integral a sus clientes. 

Paralelamente a su carrera profesional, José Tomás ha sido Profesor Asistente en la Universidad de los Andes, impartiendo cursos de Derecho Civil. 

**Educación**

- Master of Laws (LLM), Commercial and Corporate Law, University of Southampton, Reino Unido (2015 - 2016) 

- Licenciado en Derecho, Universidad de los Andes (2004 - 2008) 

**Experiencia Laboral**

- Director Área Corporativa, AnguitaOsorio (jun. 2024 - actualidad) 

- Fundador, Solent Legal (dic. 2020 - jul. 2024) 

- Socio, Cruzat, Wahl & Cía. Abogados (oct. 2016 - dic. 2020) 

- Asociado, Barros & Errázuriz Abogados (ene. 2012 - ago. 2015) 

- Asociado, Alessandri Abogados (oct. 2009 - dic. 2011) 

[![LinkedIn](${LinkedInIcon})](https://www.linkedin.com/in/jos%C3%A9-tom%C3%A1s-cuevas-err%C3%A1zuriz-b89477115/)
`

        },
        'EN':{
            image:"/images/team/josetomas.jpg",
            title:"José Tomás Cuevas",
            subtitle:"Director of the Corporate Area",
            content:`
José Tomás Cuevas is a lawyer with a solid academic background and extensive experience in the corporate and academic fields. He obtained his Law degree from the University of the Andes and a Master’s in Commercial and Corporate Law (LLM) from the University of Southampton, United Kingdom. Throughout his career, he has worked in various prestigious firms in the country, excelling in leadership roles and providing advisory services to important firms and organizations.

His career has focused on various aspects of corporate and commercial law, where he has led key projects and provided strategic advice across a wide range of economic sectors.

Currently, he is the Director of the Corporate Area at AnguitaOsorio, where he leads a team of professionals dedicated to providing comprehensive legal advice to their clients.

In parallel with his professional career, José Tomás has been an Assistant Professor at the University of the Andes, teaching Civil Law courses.

**Education**

- Master of Laws (LLM), Commercial and Corporate Law, University of Southampton, United Kingdom (2015 - 2016)

- Bachelor of Law, University of the Andes (2004 - 2008)

**Professional Experience**

- Director of the Corporate Area, AnguitaOsorio (Jun. 2024 - Present)

- Founder, Solent Legal (Dec. 2020 - Jul. 2024)

- Partner, Cruzat, Wahl & Cía. Abogados (Oct. 2016 - Dec. 2020)

- Associate, Barros & Errázuriz Abogados (Jan. 2012 - Aug. 2015)

- Associate, Alessandri Abogados (Oct. 2009 - Dec. 2011)

[![LinkedIn](${LinkedInIcon})](https://www.linkedin.com/in/jos%C3%A9-tom%C3%A1s-cuevas-err%C3%A1zuriz-b89477115/)

        `
        }
    },
    'maria_smith':{
        'ES':{
            image:"/images/team/mariavictoria.jpg",
            title:"María Victoria Smith",
            subtitle:"Abogado",
            content:`

Maria Victoria Smith (Universidad Adolfo Ibáñez - 2019) Asociada corporativa, enfoca su práctica en materias corporativas y compliance.

Licenciada en Ciencias Jurídicas y Sociales de la Universidad Adolfo Ibáñez con mención en Derecho Penal.

Realizó su práctica profesional en Fiscalía, Compañía BCI Seguros Generales S.A. (2018) y en el Estudio Jurídico Morales & Besa en el Área Corporativa y Área de Derecho Penal/Compliance (2019).

Ha sido ayudante del ciclo de Derecho Penal del Prof. Javier Wilenmann en la Universidad Adolfo Ibáñez (2018 – 2020).

Trabajó como asesora jurídica en la Fundación Scalabrini (2021).

Posee un certificado emitido por la Universitat Autònoma de Barcelona sobre Contratación y Mercado Digital, abordando aspectos legales y otras cuestiones de interés.

Actualmente, cursa un diplomado en Derecho Corporativo y Compliance en la Universidad de los Andes.

**Educación**

- Licenciada en Ciencias Jurídicas y Sociales, Universidad Adolfo Ibáñez (2019)
- Diplomado en Derecho Corporativo y Compliance, Universidad de los Andes (En curso)

**Experiencia Laboral**

- Pasantía en Fiscalía, Compañía BCI Seguros Generales S.A. (2018)
- Pasantía en el Estudio Jurídico Morales & Besa, Área Corporativa & Área Derecho Penal/Compliance (2019)
- Ayudante del ciclo de Derecho Penal - Prof. Javier Wilenmann, Universidad Adolfo Ibáñez (2018 – 2020)
- Asesora jurídica en Fundación Scalabrini (2021)

[![LinkedIn](${LinkedInIcon})](https://www.linkedin.com/in/mar%C3%ADa-victoria-smith-b23b11172/)
`

        },
        'EN':{
            image:"/images/team/mariavictoria.jpg",
            title:"María Victoria Smith",
            subtitle:"Lawyer",
            content:`

Maria Victoria Smith (Adolfo Ibáñez University - 2019) Corporate Associate, focuses her practice on corporate matters and compliance.

Bachelor in Legal and Social Sciences from Adolfo Ibáñez University, specializing in Criminal Law.

She completed her professional internship at the Prosecutor's Office, BCI Seguros Generales S.A. (2018) and at the Morales & Besa Law Firm in the Corporate Area and Criminal Law/Compliance Area (2019).

She has been a teaching assistant for the Criminal Law course with Prof. Javier Wilenmann at Adolfo Ibáñez University (2018 – 2020).

Worked as a legal advisor at the Scalabrini Foundation (2021).

Holds a certificate issued by the Autonomous University of Barcelona on Contracting and the Digital Market, addressing legal aspects and other issues of interest.

Currently pursuing a diploma in Corporate Law and Compliance at the University of Los Andes.

**Education**

- Bachelor in Legal and Social Sciences, Adolfo Ibáñez University (2019)
- Diploma in Corporate Law and Compliance, University of Los Andes (Ongoing)

**Work Experience**

- Internship at the Prosecutor's Office, BCI Seguros Generales S.A. (2018)
- Internship at Morales & Besa Law Firm, Corporate Area & Criminal Law/Compliance Area (2019)
- Teaching Assistant for the Criminal Law course with Prof. Javier Wilenmann, Adolfo Ibáñez University (2018 – 2020)
- Legal Advisor at Scalabrini Foundation (2021)


[![LinkedIn](${LinkedInIcon})](https://www.linkedin.com/in/mar%C3%ADa-victoria-smith-b23b11172/)

        `
        }
    },
    'francisca_nunez':{
        'ES':{
            image:"/images/team/franciscanunez.jpg",
            title:"Francisca Núñez Valderrama",
            subtitle:"Abogada",
            content:`
Abogada y Licenciada en Ciencias Jurídicas de la Universidad de los Andes con Mención en Derecho de Responsabilidad Civil.

Realizó su práctica profesional en el Servicio Nacional de Migraciones (2022-2023), donde participó activamente en la redacción de escritos judiciales, alegatos en Cortes de Apelación y la intervención en audiencias penales.

Cuenta con un enfoque práctico en temas de propiedad intelectual, derecho civil, y su formación se complementa con un diplomado en Tributación de la Empresa de la Universidad del Desarrollo.

**Educación**

- Diplomado en Tributación de la Empresa, Universidad del Desarrollo (2024)
- Licenciada en Ciencias Jurídicas, Universidad de los Andes (2024)
- Diploma de estudios profundizados en Derecho de Responsabilidad Civil, Universidad de los Andes (2022)

**Experiencia Profesional**

- Asesoría jurídica en propiedad intelectual, derecho civil y corporativo (2024)
- Pasantía en el Servicio Nacional de Migraciones, Departamento Judicial (2022 – 2023)
- Ayudante de Historia del Derecho y Derecho Romano, Universidad de los Andes (2017 – 2019)

**Idiomas**

- Español
- Inglés

[![LinkedIn](${LinkedInIcon})](https://www.linkedin.com/in/francisca-n%C3%BA%C3%B1ez-valderrama-18275322a/)
`
        },
        'EN': {
            image:"/images/team/franciscanunez.jpg",
            title:"Francisca Núñez Valderrama",
            subtitle:"Lawyer",
            content:`

Lawyer and Bachelor of Legal Sciences from Universidad de los Andes, with a specialization in Civil Liability Law.

Completed her professional internship at the National Migration Service (2022–2023), actively participating in drafting legal briefs, presenting arguments before Courts of Appeal, and intervening in criminal hearings.
            
She has a practical focus on intellectual property, civil law, and her education is complemented by a diploma in Corporate Taxation from Universidad del Desarrollo.
            
**Education**
            
- Diploma in Corporate Taxation, Universidad del Desarrollo (2024)
- Bachelor of Legal Sciences, Universidad de los Andes (2024)
- Advanced Studies Diploma in Civil Liability Law, Universidad de los Andes (2022)

**Professional Experience**
            
- Legal advisory in intellectual property, civil, and corporate law (2024)
- Internship at the National Migration Service, Legal Department (2022–2023)
- Teaching Assistant in History of Law and Roman Law, Universidad de los Andes (2017–2019)
            
**Languages**
            
- Spanish
- English

[![LinkedIn](${LinkedInIcon})](https://www.linkedin.com/in/francisca-n%C3%BA%C3%B1ez-valderrama-18275322a/)
`                        
        }
    },
    'jessie_mattenet':{
        'ES':{
            image:"/images/team/jessiemattenet.jpg",
            title:"Jessie Olivia Mattenet",
            subtitle:"Abogada",
            content:`
Asociada Corporativa, enfoca su práctica en materias corporativas y compliance.  

Licenciada en Ciencias Jurídicas de la Universidad de Los Andes con mención en Inglés Legal. 

Realizó su práctica profesional en Derecho de Familia en la Corporación de Asistencia Judicial de La Reina (2021).  

Actualmente cursa un diplomado en Historia del Arte en la Universidad Adolfo Ibáñez. 

Previo a su trabajo en AnguitaOsorio, Jessie trabajó en la fiscalía corporativa de Credicorp Capital Chile (2023). 

**Educación**

- Abogado, Universidad de los Andes (2019) 
- Diplomado en Historia del Arte, Universidad Adolfo Ibáñez (2024- en curso) 

**Experiencia Laboral**

- Abogado en la fiscalía corporativa de Credicorp Capital Chile (2023) 

**Idiomas**

- Español 
- Inglés  

[![LinkedIn](${LinkedInIcon})](https://www.linkedin.com/in/jessie-olivia-mattenet-blumenfeld/)
`
        },
        'EN': {
            image:"/images/team/jessiemattenet.jpg",
            title:"Jessie Olivia Mattenet",
            subtitle:"Lawyer",
            content:`

Corporate Associate focused on corporate law and compliance matters.

Holds a Bachelor of Legal Sciences from Universidad de los Andes with a specialization in Legal English.

Completed her professional internship in Family Law at the Legal Aid Corporation of La Reina (2021).

She is currently pursuing a diploma in Art History at Universidad Adolfo Ibáñez.

Prior to joining AnguitaOsorio, Jessie worked in the corporate legal department of Credicorp Capital Chile (2023).

**Education**

- Lawyer, Universidad de los Andes (2019)
- Diploma in Art History, Universidad Adolfo Ibáñez (2024 – ongoing)

**Professional Experience**

- Lawyer in the corporate legal department of Credicorp Capital Chile (2023)

**Languages**

- Spanish
- English

[![LinkedIn](${LinkedInIcon})](https://www.linkedin.com/in/jessie-olivia-mattenet-blumenfeld/)
`                        
        }
    },
    'patricio_fredes':{
        'ES':{
            image:"/images/team/patriciofredes.jpg",
            title:"Patricio Fredes Guzmán",
            subtitle:"Software Developer",
            content:`
Patricio Fredes es Técnico Informático de la Universidad Técnica Federico Santa María. 
En su carrera se ha dedicado a todos los aspectos del desarrollo, optimización y soporte técnico de soluciones de software. 
Su carrera se ha desarrollado en distintas áreas, donde se ha enfocado en el desarrollo y optimización de aplicaciones y en brindar soporte técnico a software de clientes.

**Educación**

- Técnico Informático, Universidad Técnica Federico Santa María (2021)
- Ingeniería en Informática, Duoc UC (En curso)

**Experiencia Laboral**

- AnguitaOsorio (dic. 2022 - actualidad) Desarrollador de Software
- Agencia de Aduana Juan Carlos Stephens (ago. 2022 - nov. 2022) Desarrollador de Software
- Dual Vision (nov. 2021 - feb. 2022) Practicante Software Developer


[![LinkedIn](${LinkedInIcon})](https://www.linkedin.com/in/patriciofredesti/)
`

        },
        'EN':{
            image:"/images/team/patriciofredes.jpg",
            title:"Patricio Fredes Guzmán",
            subtitle:"Software Developer",
            content:`
Patricio Fredes is an IT Technician from Technical University Federico Santa María. 
His career has been dedicated to all aspects of developing, optimizing, and providing technical support for software solutions. 
His career has spanned various areas, he has focused on developing and optimizing applications and providing technical support to client software.

**Education**

- IT Technician, Technical University Federico Santa María (2021)
- Computer Engineering, Duoc UC (Ongoing)

**Work Experience**

- AnguitaOsorio (Dec. 2022 - Present) Software Developer
- Juan Carlos Stephens Customs Agency (Aug. 2022 - Nov. 2022) Software Developer
- Dual Vision (Nov. 2021 - Feb. 2022) Intern Software Developer


[![LinkedIn](${LinkedInIcon})](https://www.linkedin.com/in/patriciofredesti/)

        `
        },  
    },    
    'contacto':{
        'type': "single_content",
        'ES':{
            image:"/images/misc/contacto.jpg",
            title:"CONTACTO",
            subtitle:"",
            content:`
**Email**

contacto@anguitaosorio.cl

**Teléfono**

+56 2 2760 4512

**Ubicación**

Los Militares 5620, oficina 905, Comuna de las Condes, Región Metropolitana.
`
        },
        'EN':{
            image:"/images/misc/contacto.jpg",
            title:"CONTACT",
            subtitle:"",
            content:`
**Email**

contacto@anguitaosorio.cl
            
**Phone**
            
+56 2 2760 4512
            
**Location**
            
Los Militares 5620, Office 905, Las Condes District, Metropolitan Region.
`
        }
    },
    'equipo':{
        'type': "single_content",
        'ES':{
            image:"",
            title:"",
            subtitle:"",
            content:`
 Con amplia experiencia en la práctica del derecho, implementación y
 desarrollo de tecnologías, nuestro equipo esta preparado para
 asesorar a nuestros clientes en todas las necesidades legales que necesiten.
`
        },
        'EN':{
            image:"",
            title:"",
            subtitle:"",
            content:`
With extensive experience in the practice of law, implementation, and development of 
technologies, our team is prepared to advise our clients on all the legal needs they may have.
`
        }
    },
    'nosotros':{
        'type':'single_content',
        'ES':{
            image:"/images/misc/026.jpg",
            title:"NOSOTROS",
            subtitle:"",
            content:`
**Visión**

AnguitaOsorio es una empresa que combina el estado del arte en tecnología con el modelo de negocios legal. Esta combinación permite potenciar servicios legales basados en el conocimiento del cliente, su negocio e industria, así como la capacidad de enfrentar materias complejas en forma disruptiva y creativa. En consecuencia, AnguitaOsorio, incorpora los valores de flexibilidad, automatización, manejo de datos, y transparencia a sus servicios.

**Prácticas y Soluciones Avanzadas**

AnguitaOsorio está comprometida a ser el estudio tecnológicamente más avanzado de Latinoamérica, otorgando a sus clientes las herramientas necesarias para enfrentar los desafíos de un mundo en constante cambio. Para lo anterior AnguitaOsorio ha desarrollado servicios legales alternativos, también llamados _alternative legal service providers_ o ALSPs, bajo la denominación Prácticas y Soluciones Avanzadas (PSA).

**Diversidad e Inclusión**

Toda empresa necesita de distintas miradas. Las distintas experiencias vividas, perspectivas e ideas bajo un mismo techo son las que generan nuevas ideas y soluciones innovadoras. En AnguitaOsorio estamos comprometidos a un cultura que promueva la diversidad, equidad e inclusión.
`
        },
        'EN':{
            image:"/images/misc/026.jpg",
            title:"ABOUT US",
            subtitle:"",
            content:`
**Vision**

AnguitaOsorio is a company that combines state-of-the-art technology with the legal business model. This combination enables the enhancement of legal services based on the knowledge of the client, their business, and industry, as well as the ability to address complex matters in a disruptive and creative way. Consequently, AnguitaOsorio incorporates values of flexibility, automation, data management, and transparency into its services.
            
**Advanced Practices and Solutions**
            
AnguitaOsorio is committed to being the most technologically advanced firm in Latin America, providing its clients with the necessary tools to face the challenges of a constantly changing world. To this end, AnguitaOsorio has developed alternative legal services, also known as alternative legal service providers or ALSPs, under the name Advanced Practices and Solutions (PSA).
            
**Diversity and Inclusion**
            
Every company needs different perspectives. The varied experiences, perspectives, and ideas under one roof are what generate new ideas and innovative solutions. At AnguitaOsorio, we are committed to a culture that promotes diversity, equity, and inclusion.
`
        }
    },
    'corporativo':{
        'type':'single_content',
        'ES':{
            image:"/images/misc/corpo.jpg",
            title:"CORPORATIVO",
            subtitle:"",
            content:`
**Corporativo**

AnguitaOsorio presta servicios a sociedades de todo tipo, fiscales, directorios, comités y ejecutivos principales en aquellas materias más críticas de su empresa. Nuestro objetivo es prestar servicios con una perspectiva de negocios a largo plazo, sumado a un sentido práctico que dé solución a lo urgente. Así, AnguitaOsorio asesora a sus clientes en:
- Gobiernos corporativos
- Restructuraciones tributarias
- Fusiones y adquisiciones
- Conflictos societarios
- Acompañamiento en asuntos ante la CMF y otros reguladores
- Cumplimiento permanente en materias regulatorias
- Ciberseguridad
- Asuntos del día a día de la empresa
`
        },
        'EN':{
            image:"/images/misc/corpo.jpg",
            title:"CORPORATE",
            subtitle:"",
            content:`
**Corporate**

AnguitaOsorio provides services to companies of all types, fiscal entities, boards, committees, and key executives in the most critical matters of their business. Our goal is to provide services with a long-term business perspective, combined with a practical approach that addresses urgent needs. Thus, AnguitaOsorio advises its clients on:
            
- Corporate governance
- Tax restructurings
- Mergers and acquisitions
- Corporate disputes
- Support in matters before the CMF (Financial Market Commission) and other regulators
- Ongoing compliance in regulatory matters
- Cybersecurity
- Day-to-day business affairs
`        
        }
    },
    'vc':{
        'type':'single_content',
        'ES':{
            image:"/images/misc/vc.jpg",
            title:"VENTURE CAPITAL",
            subtitle:"",
            content:`
**Venture Capital**

AnguitaOsorio tiene un profundo conocimiento en materias de tecnología, innovación y procesos de levantamiento de capital. Así nuestros asesores apoyan en materias tales como:

- Representaciones de Fondos de Inversión
- Formación de Fondos de Inversión
- Asesoría a Startups y fundadores
- Redes de inversionistas ángeles
- Asesoría a empresas en etapa de maduración
- Asesoría a inversionistas en sus financiamientos
- Materias transfronterizas e internacionalización
- Aceleradoras de VC
`
        },
        'EN':{
            image:"/images/misc/vc.jpg",
            title:"VENTURE CAPITAL",
            subtitle:"",
            content:`
**Venture Capital**

AnguitaOsorio has a deep understanding of technology, innovation, and capital raising processes. Thus, our advisors support in areas such as:
            
- Representation of Investment Funds
- Formation of Investment Funds
- Advisory to Startups and Founders
- Angel Investor Networks
- Advisory to companies in the maturation stage
- Advisory to investors in their financings
- Cross-border and internationalization matters
- VC Accelerators
`            
        }
    },
    'compliance':{
        'type':'single_content',
        'ES':{
            image:"/images/misc/compliance.jpg",
            title:"COMPLIANCE",
            subtitle:"",
            content:`
**Compliance**

AnguitaOsorio ofrece un servicio especializado en materias de compliance, con una mirada transversal y de negocios apalancada en la tecnología. Así, AnguitaOsorio asesora a sus clientes en:

- Apoyo a gerencias de cumplimiento en su día a día
- Prevención de lavados de activos y financiamiento del terrorismo
- Capacitaciones
- Implementación de programas de prevención de delitos y otros programas sectoriales (ej., programas de libre competencia, datos, tributario, laboral.)
- Investigaciones Internas
- Acompañamiento y preparación para certificación de programa de cumplimiento
`
        },
        'EN':{
            image:"/images/misc/compliance.jpg",
            title:"COMPLIANCE",
            subtitle:"",
            content:`
**Compliance**

AnguitaOsorio offers specialized compliance services with a cross-sectional and business-focused approach, leveraged by technology. Thus, AnguitaOsorio advises its clients on:
            
- Support to compliance managements in their day-to-day operations
- Prevention of money laundering and terrorist financing
- Trainings
- Implementation of crime prevention programs and other sectoral programs (e.g., free competition, data, tax, labor programs.)
- Internal Investigations
- Assistance and preparation for compliance program certification
`            
        }
    },
    'legalops':{
        'type':'single_content',
        'ES':{
            image:"/images/misc/legalops.jpg",
            title:"LEGAL OPERATIONS",
            subtitle:"",
            content:`
**Qué es Legal Operations**

Legal Operations describe un grupo de procesos, actividades y profesionales que permiten a los departamentos legales servir a sus clientes de manera más efectiva. Legal Operations provee de la planificación estratégica, administración financiera, y experiencia tecnológica a los equipos legales.

Los equipos de Legal Operations incluyen expertos en campos como, finanzas, marketing, análisis de datos, educación, desarrollo e ingeniería. Estos profesionales trabajan con los departamentos legales para identificar inversiones estratégicas que aumentan las capacidades de estas.

Estos departamentos legales son más efectivos al momento de manejar riesgos, seguir políticas de cumplimiento, adoptar tecnologías y entregar valor a la empresa con una visión de negocio.
`
        },'EN':{
            image:"/images/misc/legalops.jpg",
            title:"LEGAL OPERATIONS",
            subtitle:"",
            content:`
**What is Legal Operations**

Legal Operations describes a set of processes, activities, and professionals that enable legal departments to serve their clients more effectively. Legal Operations provides strategic planning, financial management, and technological expertise to legal teams.
            
Legal Operations teams include experts in fields such as finance, marketing, data analysis, education, development, and engineering. These professionals work with legal departments to identify strategic investments that enhance their capabilities.
            
These legal departments are more effective in managing risks, following compliance policies, adopting technologies, and delivering value to the company with a business vision.
`
        }
    },
    'legaltech':{
        'type':'single_content',
        'ES':{
            image:"/images/misc/legaltech.jpg",
            title:"LEGAL TECHNOLOGY CONSULTING",
            subtitle:"",
            content:`
**Legal Technology Consulting**

La tecnología puede transformar la forma de trabajar de los departamentos legales y estudios jurídicos, aumentando eficiencia y visibilidad del área. En AnguitaOsorio ayudamos a fiscalías y gerencias a seleccionar, implementar y utilizar tecnología legal y de administración de contratos para lograr resultados de alto impacto.

Existe una enorme cantidad de alternativas de soluciones disponibles en el mercado. Algunas están enfocadas en departamentos legales y estudios, otras son generalistas y buscan solucionar un problema para toda la empresa, como, por ejemplo las soluciones de administración de contratos (Contract Management Systems). Esta variedad y cantidad representan un enorme desafío al momento de elegir cual solución le entrega el mayor valor al negocio.
`
        },
        'EN':{
            image:"/images/misc/legaltech.jpg",
            title:"LEGAL TECHNOLOGY CONSULTING",
            subtitle:"",
            content:`
**Legal Technology Consulting**

Technology can transform the way legal departments and law firms work, increasing efficiency and visibility of the area. At AnguitaOsorio, we assist legal departments and managements in selecting, implementing, and using legal technology and contract management solutions to achieve high-impact results.
            
There is a vast array of solution alternatives available in the market. Some are focused on legal departments and firms, while others are more generalist and aim to solve a problem for the entire company, such as Contract Management Systems. This variety and quantity represent a huge challenge when it comes to choosing which solution delivers the greatest value to the business.
            `
        }
    },
    'outsourcing':{
        'type':'single_content',
        'ES':{
            image:"/images/misc/outsourcing.jpg",
            title:"LEGAL PROCESS OUTSOURCING (LPO)",
            subtitle:"",
            content:`
**Legal Process Outsourcing**

Los departamentos legales trabajan al límite de su capacidad. El outsourcing legal (Legal Process Outsourcing - LPO), entrega una solución a este problema. LPO permite levantar equipos especializados para problemas de todo tipo, por ejemplo, que una causa requiera de experiencia en un área de particular, o manejar un flujo extraordinario de trabajo,

AnguitaOsorio ofrece distintos tipos de LPO adaptados a la necesidad de sus clientes, siempre provisto por profesionales de excelencia, para que estos puedan concentrarse en su negocio.

**Por qué considerar LPO**

Los departamentos legales pueden obtener numerosas ventajas al apalancar sus capacidades con LPO:

Conveniencia y flexibilidad. LPO libera recursos críticos en tu organización permitiendo que los equipos se concentren en agregar valor en su organización.
Manejo de costos. LPO ofrece una alternativa a a la contratacion de equipos internos, lo que permite manejar costos y presupuestos. Adicionalmente los profesionales que trabajan con AnguitaOsorio, ya tiene la experiencia y las habilidades necesarias para realizar los encargos que se les soliciten.
Experiencia al alcance. Los profesionales que trabajan con AnguitaOsorio tienen experiencia en una amplia variedad de áreas de la práctica.
Rápido y confiable. Debido a que el personal del LPO son efectivos en lo que hacen, pueden entregar rápidamente entregables de alta calidad.
`
        },
        'EN':{
            image:"/images/misc/outsourcing.jpg",
            title:"LEGAL PROCESS OUTSOURCING (LPO)",
            subtitle:"",
            content:`
**Legal Process Outsourcing**

Legal departments often operate at the limits of their capacity. Legal Process Outsourcing (LPO) provides a solution to this problem. LPO allows for the creation of specialized teams for various types of issues, for instance, when a case requires expertise in a particular area or to handle an extraordinary workload.
            
AnguitaOsorio offers various types of LPO adapted to the needs of its clients, always provided by top-notch professionals, allowing them to focus on their business.
            
**Why Consider LPO**
            
Legal departments can gain numerous advantages by leveraging their capabilities with LPO:
            
Convenience and flexibility. LPO frees up critical resources in your organization, allowing teams to focus on adding value to their organization.
Cost management. LPO offers an alternative to hiring internal teams, which helps manage costs and budgets. Additionally, the professionals working with AnguitaOsorio already have the experience and skills necessary to carry out the tasks assigned to them.
Expertise at hand. The professionals working with AnguitaOsorio have experience in a wide variety of practice areas.
Fast and reliable. Because LPO personnel are effective at what they do, they can quickly deliver high-quality outputs.
            `
        }
    },
    'politica_de_privacidad':{
        'ES':{
            title: "Politica de privacidad",
            content: `
### Política de privacidad
El sitio web anguitaosorio.cl es propiedad de Asesorías e Inversiones Celeste SpA, que es un controlador de datos de tus datos personales.

Hemos adoptado esta Política de privacidad, que determina cómo procesamos la información recopilada por anguitaosorio.cl, que también proporciona las razones por las que debemos recopilar ciertos datos personales sobre ti. Por lo tanto, debes leer esta Política de privacidad antes de usar el sitio web de anguitaosorio.cl.

Cuidamos tus datos personales y nos comprometemos a garantizar su confidencialidad y seguridad.

### Información personal que recopilamos:
Cuando visitas anguitaosorio.cl, recopilamos automáticamente cierta información sobre tu dispositivo, incluida información sobre tu navegador web, dirección IP, zona horaria y algunas de las cookies instaladas en tu dispositivo. Además, a medida que navegas, recopilamos información sobre las páginas web individuales o los productos que ves, qué sitios web o términos de búsqueda te remitieron a la web y cómo interactúas. Nos referimos a esta información recopilada automáticamente como "Información del dispositivo". Además, podemos recopilar los datos personales que nos proporcionas (incluidos, entre otros, nombre, apellido, dirección, información de pago, etc.) durante el registro para poder cumplir con el acuerdo.

### ¿Por qué procesamos tus datos?
Nuestra máxima prioridad es la seguridad de los datos del cliente y, como tal, podemos procesar solo los datos mínimos del usuario, solo en la medida en que sea absolutamente necesario para mantener el sitio web. La información recopilada automáticamente se utiliza solo para identificar casos potenciales de abuso y establecer información estadística sobre el uso del sitio web. Esta información estadística no se agrega de tal manera que identifique a ningún usuario en particular del sistema.

Puedes visitar la web sin decirnos quién eres ni revelar ninguna información por la cual alguien pueda identificarte como una persona específica. Sin embargo, si deseas utilizar algunas de las funciones del sitio web, o deseas recibir nuestro boletín informativo o proporcionar otros detalles al completar un formulario, puedes proporcionarnos datos personales, como tu correo electrónico, nombre, apellido, ciudad de residencia, organización y número de teléfono. Puedes optar por no proporcionar tus datos personales, pero es posible que no puedas aprovechar algunas de las funciones del sitio web. Por ejemplo, no podrás recibir nuestro boletín ni contactarnos directamente desde el sitio web. Los usuarios que no estén seguros de qué información es obligatoria pueden ponerse en contacto con nosotros a través de contacto@anguitaosorio.cl.

### Tus derechos:
Si eres residente Chileno, tus derechos relacionados a tus datos personales se rigen por la ley 19.628

Si eres residente europeo, tienes los siguientes derechos relacionados con tus datos personales:

* El derecho a ser informado.
* El derecho de acceso.
* El derecho a la rectificación.
* El derecho a borrar.
* El derecho a restringir el procesamiento.
* El derecho a la portabilidad de datos.
* El derecho a oponerte.
* Derechos en relación con la toma de decisiones automatizada y la elaboración de perfiles.

Si deseas ejercer este derecho, comunícate con nosotros a través de la información de contacto a continuación.

Además, si eres residente europeo, destacamos que estamos procesando tu información para cumplir con los contratos que podríamos tener contigo (por ejemplo, si realizas un pedido a través de la web), o de otra manera para seguir nuestros intereses comerciales legítimos enumerados anteriormente. Además, ten en cuenta que tu información puede transferirse fuera de Europa, incluidos Canadá y Estados Unidos.

### Enlaces a otros sitios web:
Nuestra web puede contener enlaces a otros sitios web que no son de nuestra propiedad ni están controlados por nosotros. Ten en cuenta que no somos responsables de dichos sitios web ni de las prácticas de privacidad de terceros. Te recomendamos que estés atento cuando abandones nuestro sitio web y leas las declaraciones de privacidad de cada web que pueda recopilar información personal.

### Seguridad de la información:
Aseguramos la información que proporcionas en servidores informáticos en un entorno controlado y seguro, protegido del acceso, uso o divulgación no autorizados. Mantenemos medidas de seguridad administrativas, técnicas y físicas razonables para proteger contra el acceso no autorizado, el uso, la modificación y la divulgación de datos personales bajo su control y custodia. Sin embargo, no se puede garantizar la transmisión de datos a través de Internet o redes inalámbricas.

### Divulgación legal:
Divulgaremos cualquier información que recopilemos, usemos o recibamos si así lo requiere o lo permite la ley, como para cumplir con una citación o un proceso legal similar, y cuando creemos de buena fe que la divulgación es necesaria para proteger nuestros derechos, proteger tu seguridad o la seguridad de los demás, investigar el fraude o responder a una solicitud del gobierno.

### Información de contacto:
Si deseas comunicarte con nosotros para comprender más sobre esta Política o deseas comunicarte con nosotros en relación con cualquier asunto sobre los derechos individuales y tu información personal, puedes enviarnos un correo electrónico a contacto@anguitaosorio.cl.
`
        },
        'EN': {
            title: "Privacy Policy",
            content: `
### Privacy Policy
The website anguitaosorio.cl is owned by Asesorías e Inversiones Celeste SpA, which is a data controller of your personal data.

We have adopted this Privacy Policy, which determines how we process the information collected by anguitaosorio.cl, and also provides the reasons why we need to collect certain personal data about you. Therefore, you should read this Privacy Policy before using the anguitaosorio.cl website.

We care about your personal data and are committed to ensuring its confidentiality and security.

### Personal Information We Collect:
When you visit anguitaosorio.cl, we automatically collect certain information about your device, including information about your web browser, IP address, time zone, and some of the cookies installed on your device. Additionally, as you browse, we collect information about the individual web pages or products you view, what websites or search terms referred you to the site, and how you interact with it. We refer to this automatically collected information as "Device Information." Furthermore, we may collect the personal data you provide us (including, but not limited to, name, surname, address, payment information, etc.) during registration in order to fulfill the agreement.

### Why Do We Process Your Data?
Our highest priority is the security of customer data, and as such, we may process only the minimum user data, only to the extent absolutely necessary to maintain the website. The automatically collected information is used only to identify potential cases of abuse and establish statistical information about website usage. This statistical information is not aggregated in such a way that would identify any particular user of the system.

You can visit the website without telling us who you are or revealing any information by which someone could identify you as a specific person. However, if you wish to use some of the website's features, or want to receive our newsletter or provide other details by completing a form, you may provide us with personal data, such as your email, name, surname, city of residence, organization, and phone number. You can choose not to provide your personal data, but you may not be able to take advantage of some of the website's features. For example, you will not be able to receive our newsletter or contact us directly from the website. Users who are not sure which information is mandatory can contact us through contacto@anguitaosorio.cl.

### Your Rights:
If you are a resident of Chile, your rights related to your personal data are governed by Law 19.628.

If you are a European resident, you have the following rights related to your personal data:

* The right to be informed.
* The right of access.
* The right to rectification.
* The right to erasure.
* The right to restrict processing.
* The right to data portability.
* The right to object.
* Rights in relation to automated decision-making and profiling.

If you wish to exercise this right, please contact us using the contact information below.

Additionally, if you are a European resident, please note that we are processing your information to fulfill contracts we might have with you (for example, if you make an order through the site), or otherwise to pursue our legitimate business interests listed above. Also, please note that your information may be transferred outside of Europe, including to Canada and the United States.

### Links to Other Websites:
Our website may contain links to other websites that are not owned or controlled by us. Please be aware that we are not responsible for such websites or the privacy practices of third parties. We encourage you to be aware when you leave our website and to read the privacy statements of each website that may collect personal information.

### Information Security:
We secure the information you provide on computer servers in a controlled, secure environment, protected from unauthorized access, use, or disclosure. We maintain reasonable administrative, technical, and physical safeguards to protect against unauthorized access, use, modification, and disclosure of personal data under our control and custody. However, no data transmission over the Internet or wireless network can be guaranteed.

### Legal Disclosure:
We will disclose any information we collect, use, or receive if required or permitted by law, such as to comply with a subpoena, or similar legal process, and when we believe in good faith that disclosure is necessary to protect our rights, protect your safety or the safety of others, investigate fraud, or respond to a government request.

### Contact Information:
If you wish to contact us to understand more about this Policy or wish to contact us concerning any matter relating to individual rights and your personal information, you can send an email to contacto@anguitaosorio.cl.        
`
        },
    }
}

export function getWebsiteData(id, language) {
    const default_language = "ES";
    if (website_data[id] && website_data[id][language]) {
      return website_data[id][language];
    }
    return website_data[id] ? website_data[id][default_language] : null;
}
