import {Col, Row} from "react-bootstrap";
import {StyledSection} from "../styles/Section.styled";
import {
    StyledAccentedP,
    StyledTeamsDivider,
    StyledTeamSmallRoundPicDiv,
    StyledTeamSmallRoundPicImg,
    StyledLinkedin,
    StyledRowTeams,
    StyledNuestroEquipo
} from "../styles/Teams.styled";
import {Linkedin} from "react-bootstrap-icons";
import {Link} from "react-router-dom";
import { useTranslation } from 'react-i18next';

const Team = (props) => {
    const { t } = useTranslation(); 

    return (
    <StyledSection>
        <Row>
            <StyledNuestroEquipo><Col md={12}><h2>{t('equipo.titulo')}</h2><StyledTeamsDivider /></Col></StyledNuestroEquipo>
        </Row>
        <StyledRowTeams><Row>
            <Col md={2}/>
            <Col md={8}><p>{props.description}</p></Col>
            <Col md={2}/>
        </Row></StyledRowTeams>
        <Row style={{margin:"50px"}}>
            <Col lg={6} style={{padding:"50px"}}>
                <Row md={12}>
                    <Col md={3}>
                        <StyledTeamSmallRoundPicDiv>
                        <Link to={"/Eduardoanguita"}>
                            <StyledTeamSmallRoundPicImg  src={require("../../images/team/eduardoanguita.jpg")} alt="Team Member" /></Link>
                        </StyledTeamSmallRoundPicDiv>
                    </Col>
                    <Col md={9}>
                        <div>
                            <h4 className="team-block-name">EDUARDO ANGUITA</h4>
                            <StyledAccentedP>
                                {t('equipo.subED')}
                            </StyledAccentedP>
                            <a href={"https://www.linkedin.com/in/eduardo-anguita-osorio-7bb682108/"}><StyledLinkedin><Linkedin/></StyledLinkedin></a>
                        </div>
                    </Col>
                </Row>
            </Col>
           <Col lg={6} style={{padding:"50px"}}>
               <Row sm={12}>
                   <Col sm={3}>
                       <StyledTeamSmallRoundPicDiv>
                           <Link to={"/Angelanguita"}>
                               <StyledTeamSmallRoundPicImg src={require("../../images/team/angelanguita.jpg")} alt="Team Member" /></Link>
                       </StyledTeamSmallRoundPicDiv>
                   </Col>
                   <Col md={9}>
                       <div>
                           <h4 >ÁNGEL ANGUITA</h4>
                           <StyledAccentedP>
                            {t('equipo.subAN')}
                           </StyledAccentedP>
                           <a href={"https://www.linkedin.com/in/angel-anguita-osorio/"}><StyledLinkedin><Linkedin/></StyledLinkedin></a>
                       </div>
                   </Col>
               </Row>
            </Col>
            <Col lg={6} style={{padding:"50px"}}>
               <Row sm={12}>
                   <Col sm={3}>
                       <StyledTeamSmallRoundPicDiv>
                           <Link to={"/Josetomascuevas"}>
                               <StyledTeamSmallRoundPicImg src={require("../../images/team/josetomas.jpg")} alt="Team Member" /></Link>
                       </StyledTeamSmallRoundPicDiv>
                   </Col>
                   <Col md={9}>
                       <div>
                           <h4 >JOSÉ TOMÁS CUEVAS</h4>
                           <StyledAccentedP>
                            {t('equipo.subJT')}
                           </StyledAccentedP>
                           <a href={"https://www.linkedin.com/in/jos%C3%A9-tom%C3%A1s-cuevas-err%C3%A1zuriz-b89477115/"}><StyledLinkedin><Linkedin/></StyledLinkedin></a>
                       </div>
                   </Col>
               </Row>
            </Col>
            <Col lg={6} style={{padding:"50px"}}>
               <Row sm={12}>
                   <Col sm={3}>
                       <StyledTeamSmallRoundPicDiv>
                           <Link to={"/Nathaliekoenig"}>
                               <StyledTeamSmallRoundPicImg src={require("../../images/team/nathaliekoenig.jpg")} alt="Team Member" /></Link>
                       </StyledTeamSmallRoundPicDiv>
                   </Col>
                   <Col md={9}>
                       <div>
                           <h4 >NATHALIE KOENIG</h4>
                           <StyledAccentedP>
                            {t('equipo.subNK')}
                           </StyledAccentedP>
                           <a href={"https://www.linkedin.com/in/nathalie-koenig-piffaut-7460421a5/"}><StyledLinkedin><Linkedin/></StyledLinkedin></a>
                       </div>
                   </Col>
               </Row>
            </Col>
            <Col lg={6} style={{padding:"50px"}}>
               <Row sm={12}>
                   <Col sm={3}>
                       <StyledTeamSmallRoundPicDiv>
                           <Link to={"/Andresmatheus"}>
                               <StyledTeamSmallRoundPicImg src={require("../../images/team/andresmatheus.jpg")} alt="Team Member" /></Link>
                       </StyledTeamSmallRoundPicDiv>
                   </Col>
                   <Col md={9}>
                       <div>
                           <h4 >ANDRES MATHEUS CASANOVA</h4>
                           <StyledAccentedP>
                            {t('equipo.subAM')}
                           </StyledAccentedP>
                           <a href={"https://www.linkedin.com/in/andr%C3%A9s-matheus-casanova-4512801a0//"}><StyledLinkedin><Linkedin/></StyledLinkedin></a>
                       </div>
                   </Col>
               </Row>
            </Col>
            <Col lg={6} style={{padding:"50px"}}>
               <Row sm={12}>
                   <Col sm={3}>
                       <StyledTeamSmallRoundPicDiv>
                           <Link to={"/Mariavictoriasmith"}>
                               <StyledTeamSmallRoundPicImg src={require("../../images/team/mariavictoria.jpg")} alt="Team Member" /></Link>
                       </StyledTeamSmallRoundPicDiv>
                   </Col>
                   <Col md={9}>
                       <div>
                           <h4 >MARIA VICTORIA SMITH</h4>
                           <StyledAccentedP>
                            {t('equipo.subMV')}
                           </StyledAccentedP>
                           <a href={"https://www.linkedin.com/in/mar%C3%ADa-victoria-smith-b23b11172/"}><StyledLinkedin><Linkedin/></StyledLinkedin></a>
                       </div>
                   </Col>
               </Row>
            </Col>
            <Col lg={6} style={{padding:"50px"}}>
               <Row sm={12}>
                   <Col sm={3}>
                       <StyledTeamSmallRoundPicDiv>
                           <Link to={"/Franciscanunez"}>
                               <StyledTeamSmallRoundPicImg src={require("../../images/team/franciscanunez.jpg")} alt="Team Member" /></Link>
                       </StyledTeamSmallRoundPicDiv>
                   </Col>
                   <Col md={9}>
                       <div>
                           <h4 >FRANCISCA NÚÑEZ VALDERRAMA</h4>
                           <StyledAccentedP>
                            {t('equipo.subFN')}
                           </StyledAccentedP>
                           <a href={"https://www.linkedin.com/in/francisca-n%C3%BA%C3%B1ez-valderrama-18275322a/"}><StyledLinkedin><Linkedin/></StyledLinkedin></a>
                       </div>
                   </Col>
               </Row>
            </Col>
            <Col lg={6} style={{padding:"50px"}}>
               <Row sm={12}>
                   <Col sm={3}>
                       <StyledTeamSmallRoundPicDiv>
                           <Link to={"/Jessiemattenet"}>
                               <StyledTeamSmallRoundPicImg src={require("../../images/team/jessiemattenet.jpg")} alt="Team Member" /></Link>
                       </StyledTeamSmallRoundPicDiv>
                   </Col>
                   <Col md={9}>
                       <div>
                           <h4 >JESSIE MATTENET</h4>
                           <StyledAccentedP>
                            {t('equipo.subJM')}
                           </StyledAccentedP>
                           <a href={"https://www.linkedin.com/in/jessie-olivia-mattenet-blumenfeld/"}><StyledLinkedin><Linkedin/></StyledLinkedin></a>
                       </div>
                   </Col>
               </Row>
            </Col>
            
            <Col lg={6} style={{padding:"50px"}}>
               <Row sm={12}>
                   <Col sm={3}>
                       <StyledTeamSmallRoundPicDiv>
                           <Link to={"/Karemalexander"}>
                               <StyledTeamSmallRoundPicImg src={require("../../images/team/karemalexander.jpg")} alt="Team Member" /></Link>
                       </StyledTeamSmallRoundPicDiv>
                   </Col>
                   <Col md={9}>
                       <div>
                           <h4 >KAREM ALEXANDER</h4>
                           <StyledAccentedP>
                            {t('equipo.subKA')}
                           </StyledAccentedP>
                           <a href={"https://www.linkedin.com/in/karem-alexander-5ab57811a/"}><StyledLinkedin><Linkedin/></StyledLinkedin></a>
                       </div>
                   </Col>
               </Row>
            </Col>
            <Col lg={6} style={{padding:"50px"}}>
               <Row sm={12}>
                   <Col sm={3}>
                       <StyledTeamSmallRoundPicDiv>
                           <Link to={"/Patriciofredes"}>
                               <StyledTeamSmallRoundPicImg src={require("../../images/team/patriciofredes.jpg")} alt="Team Member" /></Link>
                       </StyledTeamSmallRoundPicDiv>
                   </Col>
                   <Col md={9}>
                       <div>
                           <h4 >PATRICIO FREDES</h4>
                           <StyledAccentedP>
                            {t('equipo.subPF')}
                           </StyledAccentedP>
                           <a href={"https://www.linkedin.com/in/patriciofredesti/"}><StyledLinkedin><Linkedin/></StyledLinkedin></a>
                       </div>
                   </Col>
               </Row>
            </Col>

        </Row>
    </StyledSection>
    )
};

export default Team;
